<template>
  <v-layout column>
    <v-form ref="infoForm">
      <InfoAgreementsForm
        v-if="patient"
        :value.sync="patient"
        :syncId="syncId"
        autosave
      >
      </InfoAgreementsForm>
    </v-form>
    <v-layout :class="$vuetify.breakpoint.xsOnly && 'justify-center'">
      <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
      <DefaultButton
        bgColor="expressive_green"
        class="mx-4 px-0 mb-6"
        @click.prevent="synchronize"
        primary
        >Zapisz</DefaultButton
      >
    </v-layout>
  </v-layout>
</template>

<script>
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import PatientService from "@/services/patient.service";
import AutosaveMixin from "@/mixins/AutosaveMixin";
import DoctorReloadMixin from "@/mixins/DoctorReloadMixin";

export default {
  mixins: [ProxyCRUDMixin, AutosaveMixin, DoctorReloadMixin],
  data() {
    return {
      patient: null,
      syncId: "patientInfo",
      time: 3000,
    };
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    InfoAgreementsForm: () => import("@/components/forms/InfoAgreemensForm"),
  },
  methods: {
    fetch() {
      this.beforeRequest();
      PatientService.getPatient()
        .then((x) => {
          this.patient = x;
          this.reset();
        })
        .catch((x) => this.handleError(x));
    },
    synchronize(autosave) {
      if (!this.$refs.infoForm.validate()) {
        this.notModified();
        this.handleError("Popraw pola zaznaczone na czerwono");
        return;
      }

      this.beforeRequest();
      this.syncing();
      PatientService.editPatient(this.patient)
        .then((x) => {
          if (!autosave || this.isSyncing) {
            this.patient = x;
            this.synced();
            this.handleSuccess("Wprowadzone zmiany zostały zapisane");
          } else {
            this.reset();
          }
        })
        .catch((x) => this.handleError(x));
    },
    reloadData() {
      this.fetch();
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>